import React from 'react'
import Layout from '../components/layout'

export default function Podcasts() {
  return (
    <Layout>
      <div>
          <iframe title='-wiiz-playlist-spotify' style={{borderRadius:"12px"}} src="https://open.spotify.com/embed/playlist/111f7zDIE9yp7R4WC9VEzq?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
      </div>
    </Layout>
  )
}
